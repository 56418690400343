class DocumentsService {
    constructor(
        $rootScope,
        $upload,
        http,
        baseUrl,
        $q
    ) {
        /**
         *      POST /tender/<tender_id>/bid/<bid_id>/documents | payload: {file: <file>, data: <file_params>} - загрузить НОВЫЙ файл
         *      PUT /tender/<tender_id>/bid/<bid_id>/documents/<doc_id> | payload {file: <new_file_virsion>} - заменить файл
         *      PATCH /tender/<tender_id>/bid/<bid_id>/documents/<doc_id> | payload {…<file_params>} - редактирование параметров документа
         *
         */

        this.getListByTender = (id) => {
            let url = `tender/${id}/documents/`;

            return http.get(url).then((result) => {
                return result.data;
            });
        };
        this.saveBid = (file, id, data) => {
            let type
            if(data.docData.type){
                type = data.docData.type
                delete data.docData.type
            } else {
                type = 'documents'
            }

            let  saveUrl = `${baseUrl}tender/${id}/bid/${data.bidId}/${type}/`;
            return $upload.upload({
                url: saveUrl,
                file: file,
                data: data.docData,
            });
        }

        this.editBid = (tenderId, documentId, data, form) => {
           let resource = `tender/${tenderId}/bid/${data.bidId}/documents/${documentId}`;
            return http.patch(resource, form);

        }

        this.replaceBid = (file, tenderId, documentId, data) => {
            let replaceUrl = `${baseUrl}tender/${tenderId}/bid/${data.bidId}/documents/${documentId}/`;
            return $upload.upload({
                url: replaceUrl,
                file: file,
                data: data.docData,
                method: 'PUT'
            });
        }


        this.save = (file, id, data) => {
            let saveUrl = `${baseUrl}tender/${id}/documents/`;
            if (data) {
                if (data.contractId && data.awardId) {
                    saveUrl = `${baseUrl}tender/${id}/awards/${data.awardId}/contracts/${data.contractId}/documents/`;
                } else if (data.contractId) {
                    saveUrl = `${baseUrl}contracts/${data.contractId}/documents/`;
                } else if (data.complaintId && data.awardId) {
                    saveUrl = `${baseUrl}tender/${id}/awards/${data.awardId}/complaints/${data.complaintId}/documents`;
                } else if (data.complaintId && data.qualificationId) {
                    saveUrl = `${baseUrl}tender/${id}/qualifications/${data.qualificationId}/complaints/${data.complaintId}/documents`;
                } else if (data.complaintId && data.cancellationId) {
                    saveUrl = `${baseUrl}tender/${id}/cancellations/${data.cancellationId}/complaints/${data.complaintId}/documents`;
                } else if (data.complaintId) {
                    saveUrl = `${baseUrl}tender/${id}/complaints/${data.complaintId}/documents`;
                } else if (data.awardId) {
                    saveUrl = `${baseUrl}tender/${id}/awards/${data.awardId}/documents/`;
                } else if (data.qualificationId) {
                    saveUrl = `${baseUrl}tender/${id}/qualifications/${data.qualificationId}/documents/`;
                } else if (data.bidId && !data.documentsType) {
                    saveUrl = `${baseUrl}tender/${id}/bid/${data.bidId}/documents/`;
                } else if (data.documentsType) {
                    saveUrl = `${baseUrl}tender/${id}/bid/${data.bidId}/${data.documentsType}/`;
                } else if (data.cancellationId) {
                    saveUrl = `${baseUrl}tender/${id}/cancel/${data.cancellationId}/documents/`;
                } else if (data.itemId) {
                    saveUrl = `${baseUrl}tender/${id}/items/${data.itemId}/documents/`;
                } else if (data.lotId) {
                    saveUrl = `${baseUrl}tender/${id}/lots/${data.lotId}/documents/`;
                } else if (data.planId) {
                    saveUrl = `${baseUrl}plans/${data.planId}/documents/`;
                } else if (data.agreementId) {
                    saveUrl = `${baseUrl}agreement/${data.agreementId}/documents/`;
                } else if (data.milestoneId) {
                    saveUrl = `${baseUrl}plans/${id}/milestones/${data.milestoneId}/documents/`;
                } else if (data.complaintDOCURL) {
                    saveUrl= `${baseUrl}${data.complaintDOCURL}/documents`;
                }
            }
            if(data && data.docData){
                return $upload.upload({
                    url: saveUrl,
                    file: file,
                    data: data.docData
                });
            }

                return $upload.upload({
                    url: saveUrl,
                    file: file
                });


        };
        this.edit = (tenderId, documentId, data, form) => {
            let resource;
            if (data && data.documentsType) {
                resource = `tender/${tenderId}/bid/${data.bidId}/${data.documentsType}/${documentId}/`;
            } else if (data && data.itemId) {
                resource = `tender/${tenderId}/items/${data.itemId}/documents/${documentId}/`;
            } else if (data && data.lotId) {
                resource = `tender/${tenderId}/lots/${data.lotId}/documents/${documentId}/`;
            } else if (data && data.contractId && data.awardId) {
                resource = `tender/${tenderId}/awards/${data.awardId}/contracts/${data.contractId}/documents/${documentId}/`;
            } else if (data && data.awardId) {
                resource = `tender/${tenderId}/awards/${data.awardId}/documents/${documentId}/`;
            } else if (data && data.contractId) {
                resource = `contracts/${data.contractId}/documents/${documentId}/`;
            } else if (data && data.bidId) {

                resource = `tender/${tenderId}/bid/${data.bidId}/documents/${documentId}`;
            } else {
                resource = `tender/${tenderId}/documents/${documentId}/`;
            }
            return http.patch(resource, form);
        };
        this.replace = (file, tenderId, documentId, data) => {
            let replaceUrl = `${baseUrl}tender/${tenderId}/documents/${documentId}/`;
            if (data) {
                if (data.awardId && !data.contractId) {
                    replaceUrl = `${baseUrl}tender/${tenderId}/awards/${data.awardId}/documents/${documentId}/`;
                } else if (data.contractId && data.awardId) {
                    replaceUrl = `${baseUrl}tender/${tenderId}/awards/${data.awardId}/contracts/${data.contractId}/documents/${documentId}/`;
                } else if (data.contractId) {
                    replaceUrl = `${baseUrl}contracts/${data.contractId}/documents/${documentId}/`;
                } else if (data.qualificationId) {
                    replaceUrl = `${baseUrl}tender/${tenderId}/qualifications/${data.qualificationId}/documents/${documentId}/`;
                } else if (data.bidId && !data.documentsType) {
                    replaceUrl = `${baseUrl}tender/${tenderId}/bid/${data.bidId}/documents/${documentId}/`;
                } else if (data.documentsType) {
                    replaceUrl = `${baseUrl}tender/${tenderId}/bid/${data.bidId}/${data.documentsType}/${documentId}/`;
                } else if (data.itemId) {
                    replaceUrl = `${baseUrl}tender/${tenderId}/items/${data.itemId}/documents/${documentId}/`;
                } else if (data.lotId) {
                    replaceUrl = `${baseUrl}tender/${tenderId}/lots/${data.lotId}/documents/${documentId}/`;
                } else if (data.planId) {
                    replaceUrl = `${baseUrl}plans/${data.planId}/documents/${documentId}/`;
                } else if (data.complaintId) {
                    replaceUrl = `${baseUrl}tender/${tenderId}/complaints/${data.complaintId}/documents/${documentId}/`;
                } else if (data.agreementId) {
                    replaceUrl = `${baseUrl}agreements/${tenderId}/documents/${documentId}/`;
                    // replaceUrl = `${baseUrl}agreement/${tenderId}/documents/`;
                    // todo fix and remake
                    // this.uploadToDocumentService([file]).then(
                    //     (res) => {
                    //         file = res
                    //         console.log(res);
                    //     }
                    // );
                } else if (data.milestoneId) {
                    replaceUrl = `${baseUrl}plans/${tenderId}/milestones/${data.milestoneId}/documents/${documentId}/`;
                } else if (data.complaintDOCURL) {
                    replaceUrl = `${baseUrl}${data.complaintDOCURL}/documents/${documentId}/`;
                }
            }

            return $upload.upload({
                url: replaceUrl,
                file: file,
                method: 'PUT'
            });
        };
        this.saveDocsForBidDraft = (file, id, data) => {
            const createId = data.createId;
            let saveUrl = `${baseUrl}tender/${id}/bid/${data.bidId}/${data.type}/`;
            return $upload.upload({
                url: saveUrl,
                file: file,
                data: (data.docData) ? data.docData : {},
            })
                .then(result => {
                    return { ...result, createId };
                })
                .catch(error => {
                    return { ...error, createId };
                });
        };
        // this.deleteDocsFromBidDraft = (tenderId, documentId, data) => {
        //     const type = data.type;
        //     const resource = `${baseUrl}tender/${tenderId}/bid/${data.bidId}/documents/${documentId}/`;
        //     return http.delete(resource, true)
        //         .then(result => {
        //             return { ...result, documentId, type };
        //         })
        //         .catch(error => {
        //             return { ...error, documentId, type };
        //         });
        // }
        this.getContractDocs = (data) => {
            let url = `tender/${data.tenderId}/awards/${data.awardId}/contracts/${data.contractId}/documents/`;
            return http.get(url).then((result) => {
                return result.data;
            });
        };
        this.getContractDocsList = (data) => {
            let url = `contracts/${data.contractId}/documents/`;
            return http.get(url).then((result) => {
                return result.data;
            });
        };
        this.getBidDocs = (data) => {
            let url = `tender/${data.tenderId}/bid/${data.bidId}/documents/`;
            return http.get(url).then((result) => {
                return result.data;
            });
        };

        this.uploadFilesList = (files, tenderId, data) => {
            let deferred = $q.defer();
            let file = files.filter(file => file.status === 'waiting' || !file.status)[0];

            if (!file) {
                let uploadedFiles = files.filter(file => file.status === 'uploaded');
                deferred.resolve(uploadedFiles);
            } else {
                file.status = 'uploading';
                this.save(file, tenderId, data)
                    .then(response => {
                        if (!response.data.data) {
                            throw new Error(response.message || 'Error uploading document');
                        }
                        if (file.documentType) {
                            this.edit(tenderId, response.data.data.id, data, {
                                    documentType: file.documentType
                                })
                                .then(() => {
                                    file.status = 'uploaded';
                                })
                                .catch(() => file.status = 'error')
                                .finally(() => {
                                    this.uploadFilesList(files, tenderId, data)
                                        .then((uploadedFiles) => deferred.resolve(uploadedFiles));
                                });
                        } else {
                            file.status = 'uploaded';
                            this.uploadFilesList(files, tenderId, data)
                                .then((uploadedFiles) => deferred.resolve(uploadedFiles));
                        }
                    })
                    .catch(() => {
                        file.status = 'error';
                        this.uploadFilesList(files, tenderId, data)
                            .then((uploadedFiles) => deferred.resolve(uploadedFiles));
                    });
            }

            return deferred.promise;
        };

        this.uploadToDocumentService = (filesList, cbFun) => {
            const url = '/api/v1/documentservice/';
            return new Promise((resolve, reject) => {
                let documents = [];
                let count = 0;
                let countAll = filesList.length;
                while (filesList.length) {
                    let file = filesList.pop();
                    $upload.upload({
                        url,
                        file,
                    }).then((result) => {
                        documents.push(result.data.meta);
                        if (cbFun) {
                            count += 1;
                            cbFun(count, countAll - count); // Count file uploaded, count files left
                        }
                        if (countAll === documents.length) {
                            resolve(documents);
                        }
                    }).catch((error) => {
                        reject(error);
                    });
                }
            });
        };


        // this.sign = (data, reload = true, mustReload = false, setSign = false, checkPerson = false) => {
        this.sign = (data, setup = {}) => {
            // console.log(setup)
            const defaultSetup = {
                reload: setup.reload ? true : setup.reload,
                mustReload: setup.mustReload ? setup.mustReload : false,
                setSign: setup.setSign ? setup.setSign : false,
                checkPerson: setup.checkPerson ? setup.checkPerson : false,
                checkCompany: setup.checkCompany ? setup.checkCompany : false,
            }
            return new Promise((resolve, reject) => {
                $rootScope.edsSignIsOpened = true;
                $rootScope.edsSignData = data;
                $rootScope.edsSignSetup = defaultSetup;
                const edsSignDestroy = $rootScope.$on('edsSignResponse',  function(event, response) {
                    console.log('edsSignResponse', response);
                    resolve(response);
                    edsSignDestroy();
                });
            });
        }
    }
}
commons.service('documentsService', DocumentsService);
